<template>
  <div v-if="showTooltip">
    <div class="arrow-up" :class="[arrowPos]"></div>
    <div
      class="cell-card py-3 cell-bs d-flex rounded-sm align-items-center justify-content-between"
    >
      <div class="ml-5">
        <h4 class="m-0">
          {{ title }}
        </h4>
        <p class="small mb-1">
          {{ desc }}
        </p>
      </div>
      <div
        @click="closeTooltip()"
        class="cell-tooltip-close d-flex align-items-center justify-content-center mr-4"
      >
        <i class="ri-close-fill"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CellTooltip",
  props: {
    title: String,
    desc: String,
    arrowPos: {
      type: String,
      default: "one-two",
    },
  },
  data: function () {
    return {
      showTooltip: false,
    };
  },
  methods: {
    closeTooltip: function () {
      let id = this.title + this.desc;
      localStorage.setItem(id, true);
      this.showTooltip = false;
    },
    setTooltipVisibility: function () {
      let id = this.title + this.desc;
      this.showTooltip = localStorage.getItem(id) === null;
    },
  },
  watch: {
    title: function () {
      this.setTooltipVisibility();
    },
  },
  created: function () {
    this.setTooltipVisibility();
  },
};
</script>

<style scoped lang="scss">
.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e1e8ff;
  margin-left: 50%;
  transform: translateX(-4px);
  transition: 0.3s;
  &.one-two {
    margin-left: 25%;
  }
  &.two-two {
    margin-left: 75%;
  }
}
.cell-card {
  background-color: #e1e8ff;
  h4 {
    color: #4484ff;
  }
  p {
    color: rgba(#4484ff, 0.7);
  }
  span {
    color: rgba(#4484ff, 0.9);
  }
}
.cell-tooltip-close {
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background-color: rgba(115, 162, 255, 0.1);
  color: #4484ff;
}
</style>