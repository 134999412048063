import {
    db,
    GET
} from '@/firebase.js'
import CompCard from '@/components/CompCard/CompCard.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import ranker from '@/assets/js/ranker.js'

export default {
    name: 'RankCard',
    props: {
        title: String,
        desc: String,
        id: String,
        unit: String,
        rid: String,
        orderBy: String,
        linkToRankings: Boolean,
        rankCardIndex: Number,
        incPos: Boolean,
        icon: String,
        color: String,
        type: String,
        trends: Boolean,
    },
    components: {
        CompCard,
        Skeleton
    },
    data: function () {
        return {
            orgs: [],
        }
    },
    methods: {
        getOrgs: function () {
            let col = ranker.getQuery({
                rid: this.rid,
                type: this.type,
                orderBy: this.orderBy,
                col: db.collection('orgs'),
                eventId: this.$event.id,
                excludeNaN: true
            })
            col = col.limit(3)
            GET(col).then(orgs => {
                orgs.forEach(org => {
                    let value = ranker.getValues({
                        trends: this.trends,
                        org: org,
                        rid: this.rid,
                        type: this.type
                    })
                    this.orgs.push({
                        logo: org.data().logo,
                        name: org.data().name,
                        oid: org.data().oid,
                        category: org.data().categories ? org.data().categories[0] : null,
                        value: {
                            current: value.current,
                            trend: value.trend,
                            unit: this.unit,
                            incPos: this.incPos,
                            orderByTrend: (this.orderBy === 'trend')
                        }
                    })
                })
                this.orgs.loaded = true
            })
        },
    },
    created: function () {
        if (this.rid) this.getOrgs()
    }
}