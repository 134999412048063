import Mat from '@/components/Mat/Mat.vue'
import {
    GET,
    db
} from '@/firebase.js'

export default {
    name: 'Gad',
    props: {
        type: String,
        oid: String,
        dbRef: Object,
    },
    components: {
        Mat
    },
    data: function () {
        return {
            featuredCompany: null,
            joinAvatar: {
                src: require('@/assets/img/meta/logo-round.png'),
                type: 'img'
            },
            article: null
        }
    },
    methods: {
        getFeaturedCompanyByOid: function () {
            let col = db.collection('orgs').where('oid', '==', this.oid)
            GET(col).then(companies => {
                let company = companies.docs[0]
                this.setOrgVars(company)
            })
        },
        getFeaturedCompanyByRef: function () {
            GET(this.dbRef).then(company => {
                this.setOrgVars(company)
            })
        },
        getArticle: function () {
            let col = db.collection('articles').doc(this.$event.article.id)
            GET(col).then(article => {
                this.article = {
                    title: article.data().title,
                    cover: article.data().cover,
                    previewTitle: article.data().preview_title
                }
            })
        },
        setOrgVars: function (company) {
            this.featuredCompany = {
                name: company.data().name,
                oid: company.data().oid,
                org_nums: company.data().org_nums,
                id: company.id,
                avatar: {
                    type: 'img',
                    src: company.data().logo
                },
            }
            this.featuredCompany.image = (company.data().cover) ? company.data().cover.src : 'https://images.pexels.com/photos/3746932/pexels-photo-3746932.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
        }
    },
    created: function () {
        if (this.dbRef) this.getFeaturedCompanyByRef()
        else if (this.oid) this.getFeaturedCompanyByOid()
        else if (this.type === 'article') this.getArticle()
    }
}