import {
    GET,
    db,
} from '@/firebase.js'

import Tabs from '@/components/Tabs/Tabs.vue'
import RankCard from '@/components/RankCard/RankCard.vue'
import Loading from '@/components/Loading/Loading.vue'
import Gad from '@/components/Gad/Gad.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import CellTooltip from '@/components/CellTooltip/CellTooltip.vue'

export default {
    name: 'Rankings',
    components: {
        Tabs,
        RankCard,
        Loading,
        Gad,
        Skeleton,
        CellTooltip
    },
    data: function () {
        return {
            rankings: [],
            allRankingsFetched: false,
            lastVisible: null,
            direction: 'desc',
            rankFilter: 'Current',
            rankFilters: ['Current', 'Trends'],
            queryBusy: false,
            orderBy: '2020',
        }
    },
    methods: {
        resetQuery: function () {
            this.lastVisible = null
            this.rankings = []
            this.allRankingsFetched = false
            this.queryBusy = false
        },
        setRank: function (rank) {
            this.rankFilter = rank
            if (rank === 'Trends') this.orderBy = 'trend'
            else this.orderBy = '2020'
        },
        rankingsSort(a, b) {
            console.log(a.priority)
            if (a.priority < b.priority) {
                return -1;
            }
            if (a.priority > b.priority) {
                return 1;
            }
            return 0;
        },
        getRankings: function () {
            if (this.allRankingsFetched || this.queryBusy) return console.log('Cancel query')
            let col = db.collection('rankings')
            col = col.where('__name__', 'in', this.$event.rankings)
            //col = col.orderBy('priority', 'asc')
            if (this.lastVisible) col = col.startAfter(this.lastVisible)
            this.queryBusy = true
            GET(col).then(rankings => {
                this.lastVisible = rankings.docs[rankings.docs.length - 1]
                if (!this.lastVisible || this.rankings.some(ranking => ranking.id === this.lastVisible.id)) {
                    console.log('All rankings fetched', 'total fetch amount is: ' + this.rankings.length)
                    return this.allRankingsFetched = true
                }
                rankings.forEach(ranking => {
                    this.rankings.push({
                        name: ranking.data().name,
                        desc: ranking.data().desc,
                        id: ranking.id,
                        unit: ranking.data().unit,
                        rid: ranking.data().rid,
                        incPos: ranking.data().inc_pos,
                        color: ranking.data().color,
                        icon: ranking.data().icon,
                        priority: ranking.data().priority,
                        type: ranking.data().type,
                        trends: ranking.data().trends
                    })
                })
                this.rankings.sort(this.rankingsSort)
                this.queryBusy = false
            }).catch(err => {
                alert(err)
            })
        }
    },
    created: function () {
        console.log('Rankings loaded')
        if (this.rankings.length === 0) this.getRankings()
    }
}