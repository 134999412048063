export default {
    getQuery: function ({
        col,
        rid,
        orderBy,
        type,
        eventId,
        excludeNaN
    }) {
        if (eventId) {
            col = col.where('events.' + eventId, '==', true)
        }
        if (!rid) return null
        if (rid.split('_')[1] === 'eq') {
            let orderByQuery = (orderBy === 'trend') ? orderBy : orderBy + '.normalized'
            col = col.orderBy('KPI.' + rid + '.' + orderByQuery, 'desc')
        } else if (rid.split('_')[1] === 'ghg') {
            let orderByQuery = (orderBy === 'trend') ? orderBy : orderBy + '.raw'
            if (orderBy !== 'trend' && excludeNaN) {
                col = col.where('KPI.' + rid + '.' + orderByQuery, '>', 0)
            }
            col = col.orderBy('KPI.' + rid + '.' + orderByQuery, 'asc')
        } else if (type === 'count') {
            col = col.orderBy(rid, 'desc')
        }
        return col
    },
    getValues: function ({
        trends,
        org,
        rid,
        type
    }) {
        let currentData;
        if (type === 'count') {
            currentData = org.data().counts.badges
        } else {
            currentData = (org.data().KPI[rid]['2020'].raw) ? org.data().KPI[rid]['2020'].raw : org.data().KPI[rid]['2020'].normalized
        }
        let trend = (trends) ? trend = org.data().KPI[rid].trend : null
        return {
            current: currentData,
            trend: trend
        }
    }
}