import Tooltip from '@/components/Tooltip/Tooltip.vue'
export default {
    name: 'Tabs',
    props: {
        tabs: Array,
        activeTab: String,
        updateCB: Function,
        hideTooltip: Boolean,
        pinIndex: Number,
    },
    components: {
        Tooltip
    },
    data: function () {
        return {
            activeIndex: 0,
            showTooltip: false,
        }
    },
    methods: {
        setActiveTab: function (tab) {
            this.updateCB(tab)
            this.showTooltip = false
        },
        setTooltipStatus: function () {
            if (localStorage.getItem('tab-tooltip') || this.hideTooltip) return false
            localStorage.setItem('tab-tooltip', true)
            this.showTooltip = true
        }
    },
    watch: {
        activeTab: function () {
            this.activeIndex = this.tabs.indexOf(this.activeTab)
        }
    },
    created: function () {
        this.setTooltipStatus()
    }
}